import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpResponse } from 'src/app/shared/infrastructure/http/HttpResponse';
import * as moment from 'moment';

export interface PlatformsApiInterface {
  id?: string;
  platformId?: string;
  name: string;
  enabled: boolean;
  logoUrl: string | File;
  qty?: string;
  hexPrimaryColor: string;
  hexSecondaryColor: string;
  customCss: string;
  createdAt: string;
}

export interface PlatformsApiDTO {
  id?: string;
  platformId?: string;
  name: string;
  enabled: boolean;
  logoUrl: string | File;
  hexPrimaryColor: string;
  hexSecondaryColor: string;
  customCss: string;
  createdAt: string;
}

@Injectable({
  providedIn: "root",
})
export class PalataformsApiService {
  constructor(private httpClient: HttpClient) {}

  get(): Observable<PlatformsApiInterface> {
    return this.httpClient.get<HttpResponse<PlatformsApiDTO>>(`${environment.api_url}/plataforms`)
      .pipe(map(response => response.data));
  }

  getById(id: string): Observable<PlatformsApiInterface> {
    return this.httpClient.get<HttpResponse<PlatformsApiDTO>>(`${environment.api_url}/plataforms/${id}`)
      .pipe(map(response => mapToApiInterface(response.data)));
  }

  getByQuery(query: string){
    return this.httpClient.get<HttpResponse<PlatformsApiDTO[]>>(`${environment.api_url}/plataforms/filter/${query}`)
      // .pipe(map(response => mapToApiInterface(response.data)));
      .pipe(map(response => response.data));
  }
  getByQuerySelector(query: string){
    return this.httpClient.get<HttpResponse<PlatformsApiDTO[]>>(`${environment.api_url}/plataforms/filter/${query}/selector`)
      // .pipe(map(response => mapToApiInterface(response.data)));
      .pipe(map(response => response.data));
  }


  save(data: PlatformsApiInterface): Observable<PlatformsApiInterface> {
    return this.httpClient.post<HttpResponse<PlatformsApiDTO>>(`${environment.api_url}/plataforms`, data)
      .pipe(map(response => mapToApiInterface(response.data)));
  }

  update(id: string, data: PlatformsApiInterface): Observable<PlatformsApiInterface> {
    return this.httpClient.put<HttpResponse<PlatformsApiDTO>>(`${environment.api_url}/plataforms/${id}`, data)
      .pipe(map(response => mapToApiInterface(response.data)));
  }

  remove(id: string): Observable<void> {
    return this.httpClient.delete<HttpResponse<void>>(`${environment.api_url}/plataforms/${id}`)
      .pipe(map(response => response.data));
  }
}

function mapToApiInterface(dto: PlatformsApiDTO): PlatformsApiInterface {
  return {
    id: dto.id,
    name: dto.name,
    enabled: dto.enabled,
    logoUrl: dto.logoUrl,
    hexPrimaryColor: dto.hexPrimaryColor,
    hexSecondaryColor: dto.hexSecondaryColor,
    customCss: dto.customCss,
    createdAt: dto.createdAt,
  }
}

function mapToDTO(dto: PlatformsApiInterface): PlatformsApiDTO {
  return {
    id: dto.id,
    name: dto.name,
    enabled: dto.enabled,
    logoUrl: dto.logoUrl,
    hexPrimaryColor: dto.hexPrimaryColor,
    hexSecondaryColor: dto.hexSecondaryColor,
    customCss: dto.customCss,
    createdAt: dto.createdAt,
  }
}
