<!--<ng-container *transloco="let t; prefix: 'PLATFORM-SELECTOR'">
  <div class="container vh-100 d-flex align-items-center">
    <div class="w-100" *ngIf="platforms">
      <h3 class="my-3">{{ t('title') }}</h3>

      <div class="my-5">
        <label class="form-label ps-2">
          {{ t('texts.selectorLabel') }}
        </label>
        <div>
          <p-dropdown
            [options]="platforms"
            [(ngModel)]="selectedPlatform"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{t('texts.selectorPlaceholder')}}">
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div class="flex align-items-center gap-2">
                <div>{{ selectedOption.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

        <button class="btn btn-primary mt-4" (click)="switchPlatform()">
          {{ t('texts.selectPlatform') }}
        </button>
      </div>
    </div>

  </div>
</ng-container>-->
<main class="d-flex flex-column min-vh-100">

  <ng-container *transloco="let t; prefix: 'PLATFORMS'">
    <div class="container py-5 flex-grow-1">
      <h3>   {{ 'PLATFORM-SELECTOR.title' | transloco }} </h3>


      <div class="my-5">
        <div class="row justify-content-between mb-3 align-items-end">
          <div class="col-md-6">
            <label class="form-label">
              {{ t('texts.searchLabel') }}
            </label>
            <div class="input-group">
              <input (keyup)="getPlatforms()" [(ngModel)]="filterText" class="form-control border-end-0"
                     placeholder="{{ t('texts.searchPlaceholder') }}"
              >
              <span class="input-group-text input-span-complementary border-start-0">
              <i class="fa-solid fa-magnifying-glass"></i>
            </span>
            </div>
          </div>
        </div>

        <div *ngIf="platforms && platforms.length" class="row mt-2  pb-3 g-4 g-lg-3 g-xl-4 g-xxl-5">
          <div *ngFor="let platform of platforms" class="col-12 col-4 col-lg-3 card-group">
            <!--        <div *ngFor="let platform of platforms" class="col-12 col-md-3 " >-->
            <div [ngStyle]="{'--custom-card-border-color': platform.hexPrimaryColor || '#bababa'}"
                 class="card custom-card shadow-sm p-3 rounded-4 border-0 border-top border-5">
              <div [ngStyle]="{
                   'background-image': 'url(' + platform.logoUrl + ')',
                   'background-size': 'cover',
                   'height': '256px'
                 }"
                   class="mb-3 rounded-3">
              </div>

              <p class="text-primary fw-medium mb-1 overflow-ellipsis card-body p-0">
                {{ platform.name }}
              </p>
              <hr class="my-2" style="max-width: 237px">

              <div class="row card-footer border-0 bg-transparent p-0">
                <!--<div class="col-7">
                   <span class="text-secondary fw-semibold">
                    {{ t('texts.avgScore', {score: 80}) }}
                  </span>
                </div>-->

                <div class="mt-3">
                  <button class="btn btn-primary w-100" (click)="switchPlatformByPlatformObj(platform)">
                    {{ 'PLATFORM-SELECTOR.texts.selectPlatform' | transloco }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <app-footer></app-footer>
</main>
