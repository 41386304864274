import {Component, inject, OnDestroy} from '@angular/core';
import {TranslocoDirective, TranslocoPipe} from "@jsverse/transloco";
import {DropdownModule} from "primeng/dropdown";
import {Subscription} from "rxjs";
import {PlatformStoreService} from "../../shared/infrastructure/services/platform-store.service";
import {PalataformsApiService, PlatformsApiDTO, PlatformsApiInterface} from "../palataforms/palataforms.service";
import {FormsModule} from "@angular/forms";
import {Router} from "@angular/router";
import {NgForOf, NgIf, NgStyle} from "@angular/common";
import {FooterComponent} from "../../core/core-components/footer/footer.component";
import {environment} from 'src/environments/environment';


@Component({
  selector: 'app-platform-selector',
  standalone: true,
  imports: [
    TranslocoDirective,
    DropdownModule,
    FormsModule,
    NgIf,
    FooterComponent,
    NgForOf,
    NgStyle,
    TranslocoPipe
  ],
  templateUrl: './platform-selector.component.html',
  styleUrl: './platform-selector.component.scss'
})
export class PlatformSelectorComponent implements OnDestroy {
  router = inject(Router);

  subscriptions: Subscription[] = []
  platforms!: PlatformsApiInterface[]
  selectedPlatform?: PlatformsApiInterface
  filterText: string = ''


  constructor(
    private platformStore: PlatformStoreService,
    private route: Router,
    private apiService: PalataformsApiService,
  ) {
    this.subscriptions.push(
      this.platformStore.availablePlatforms.subscribe(platforms => {
          if (platforms?.length) {
            this.platforms = platforms
            for (const platform of platforms) {
              platform.logoUrl = this.getImageUrl(platform)
            }
          } else this.route.navigate(['/platforms'])
        }
      ),
      this.platformStore.platform.subscribe(platform => {
        this.selectedPlatform = platform
      })
    )
  }

  switchPlatform() {
    this.subscriptions.push(
      this.platformStore.platform.subscribe(platform => {
        if (platform) this.router.navigate(['/activities'])
      })
    )

    this.platformStore.setPlatform(this.platforms.find(platform => platform.id == this.selectedPlatform!.id)!)
  }

  switchPlatformByPlatformObj(platform: PlatformsApiDTO) {
    this.subscriptions.push(
      this.platformStore.platform.subscribe(platform => {
        if (platform) this.router.navigate(['/activities'])
      })
    )

    this.platformStore.setPlatform(platform)
  }

  getImageUrl(platform: any): string {
    if (platform.logoUrl) {
      return `${this.environment.api_url}${platform.logoUrl}?v=${new Date().getTime()}`;
    }
    return 'assets/images/placeholder.png';
  }

  getPlatforms() {
    this.subscriptions.push(
      this.apiService.getByQuerySelector(this.filterText || '*')
        .subscribe({
          next: response => {
            for (const platform of response) {
              platform.logoUrl = this.getImageUrl(platform)
            }
            this.platforms = response
          },
          error: error => {
            console.log(error)
          }
        })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  protected readonly environment = environment;

}
