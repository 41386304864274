import {Injectable} from '@angular/core';
import {PlatformStoreService} from "./platform-store.service";

@Injectable({
  providedIn: 'root'
})
export class PlatformThemeService {

  defaultPrimary = '#19315D'
  defaultSeconadry = '#19315D'

  constructor(
    private platformStore: PlatformStoreService
  ) {
    this.setDefaultColors()
    this.platformStore.platform.subscribe((platform) => {
      if (platform ) {
        const element = document.getElementById('custom-css');
        if (element) {
          element.remove();
        }
        const styles = this.styleStringGenerator(platform.hexPrimaryColor || this.defaultPrimary, platform.hexSecondaryColor || this.defaultSeconadry)
        this.setStyles(styles )
        // this.setStyles(styles + (platform.customCss || ''))
        this.setCustomCss(platform.customCss || '')
      }
    })
  }

  setDefaultColors(){
    this.defaultPrimary = getComputedStyle(document.documentElement).getPropertyValue('--primary').trim()
    this.defaultSeconadry = getComputedStyle(document.documentElement).getPropertyValue('--secondary').trim()
  }

  setStyles(styles: string) {
    document.body.setAttribute('style', styles)
  }

  setCustomCss(customCss: string){
    const styleSheet = document.createElement('style');
    styleSheet.id = 'custom-css'
    styleSheet.textContent = `@layer custom { ${customCss} }`;
    document.head.appendChild(styleSheet);
  }


  styleStringGenerator(primary: string, secondary: string) {
    const isPrimaryLight = this.isColorTooLight(primary)
    const isSecondaryLight = this.isColorTooLight(primary)

    const primaryText = isPrimaryLight ? '#171717' : '#FFF'
    const secondaryText = isSecondaryLight ? '#171717' : '#FFF'
    const primaryActive = this.adjustColor(primary, 25, !isPrimaryLight)
    const primaryHover = this.adjustColor(primary, 10, !isPrimaryLight)
    const secondaryActive = this.adjustColor(secondary, 25, !isSecondaryLight)
    const secondaryHover = this.adjustColor(secondary, 10, !isSecondaryLight)

    return `
      --primary: ${primary};
      --primary-active: ${primaryActive};
      --primary-hover: ${primaryHover};
      --secondary: ${secondary};
      --secondary-active: ${secondaryActive};
      --secondary-hover: ${secondaryHover};
      --custom-navbar-bg: ${primary};
      --bs-btn-active-bg: ${primary};
      --primary-component-text-color: ${primaryText};
      --secondary-component-text-color: ${secondaryText};
    `
  }

  adjustColor(color: string, percent: number, isDark: boolean = true): string {
    let num = parseInt(color.slice(1), 16);
    let amt = Math.round(2.55 * percent); // Calcula la cantidad para ajustar el brillo

    // Determina si se debe oscurecer o aclarar
    let R = (num >> 16) + (isDark ? -amt : amt);
    let G = (num >> 8 & 0x00FF) + (isDark ? -amt : amt);
    let B = (num & 0x0000FF) + (isDark ? -amt : amt);

    // Asegurarse de que los valores no excedan el rango permitido de 0 a 255
    R = Math.min(255, Math.max(0, R));
    G = Math.min(255, Math.max(0, G));
    B = Math.min(255, Math.max(0, B));

    // Retornar el color modificado en formato hexadecimal
    return "#" + (
      (1 << 24) + (R << 16) + (G << 8) + B
    ).toString(16).slice(1).toUpperCase();
  }

  luminance(r: number, g: number, b: number): number {
    // Calcular la luminancia relativa
    const a = [r, g, b].map((c) => {
      c /= 255; // normalizar
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    });

    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722; // fórmula de luminancia
  }

  isColorTooLight(hex: string, threshold: number = 0.6): boolean {
    const rgb = this.hexToRgb(hex);

    if (!rgb) {
      return false; // Color inválido
    }

    const lum = this.luminance(rgb.r, rgb.g, rgb.b);
    return lum > threshold; // Devuelve true si el color es demasiado claro
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    // Verificar si el formato del hex es válido
    const hexRegex = /^#([0-9A-Fa-f]{3}){1,2}$/;
    if (!hexRegex.test(hex)) {
      console.error("Formato hexadecimal no válido.");
      return null;
    }

    // Eliminar el signo "#" y convertir a RGB
    let r: number, g: number, b: number;

    if (hex.length === 4) { // formato corto #RGB
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else { // formato largo #RRGGBB
      r = parseInt(hex.slice(1, 3), 16);
      g = parseInt(hex.slice(3, 5), 16);
      b = parseInt(hex.slice(5, 7), 16);
    }

    return {r, g, b};
  }
}
